import { useRef, useEffect } from 'react';
import { clsx } from 'clsx';
import scrollIntoView from 'scroll-into-view-if-needed';
import { Heading } from 'nextra';
import { useMobileMenu } from '@/nextra-theme/MobileMenuContext';
import { useNormalizedPages } from '@/nextra-theme/NormalizedPagesContext';
import {
  ErrorBoundary,
  Search,
  ThemeSwitch,
  DirectoriesMenu,
} from '@/nextra-theme/components';

/**
 * On mobile devices, renders the hamburger menu contents when the menu is
 * open/triggered. When not on mobile, doesn't render anything.
 */
export function MobileMenu({ toc }: { toc: Heading[] }) {
  // Menu shows full directories, which includes links that would normally be
  // in the top NavBar on desktop, but are hidden in the NavBar on mobile
  const { directories: fullDirectories } = useNormalizedPages();

  const mobileMenu = useMobileMenu();
  const containerRef = useRef<HTMLElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);

  // Find the active list item in the sidebar and scroll it into view
  // when the mobile menu is opened
  useEffect(() => {
    const activeElement = menuRef.current?.querySelector('li.active');
    if (activeElement && mobileMenu.isOpen) {
      const scroll = () => {
        scrollIntoView(activeElement, {
          block: 'center',
          inline: 'center',
          scrollMode: 'always',
          boundary: containerRef.current,
        });
      };

      // Delay for mobile since menu has transition transform
      setTimeout(scroll, 300);
    }
  }, [mobileMenu.isOpen]);

  // Nothing to render if mobile menu is not available or if we aren't sure yet
  if (mobileMenu.isAvailable !== true) {
    return null;
  }

  return (
    <>
      {/* Overlay for when the mobile menu is open to "dim" the things behind it and allow tapping away to close it */}
      <div
        className={clsx(
          '[transition:background-color_1.5s_ease] motion-reduce:transition-none',
          mobileMenu.isOpen
            ? 'fixed inset-0 z-10 bg-black/80 dark:bg-black/60'
            : 'bg-transparent'
        )}
        onClick={() => mobileMenu.setIsOpen(false)}
      />

      <aside
        className={clsx(
          'bg-white dark:bg-dark',
          'fixed bottom-0 top-0 z-[15] w-full overscroll-contain',
          'pt-[calc(var(--chrono-navbar-height))]',
          'flex flex-col',
          'motion-reduce:transform-none',
          'transition-transform duration-700 will-change-transform [backface-visibility:hidden] [contain:layout_style]',
          mobileMenu.isOpen
            ? '[transform:translate3d(0,0,0)]'
            : '[transform:translate3d(0,-100%,0)]',
          'print:hidden'
        )}
        ref={containerRef}
      >
        <ErrorBoundary>
          <div className="px-4 pt-4">
            <Search />
          </div>

          <div
            className={clsx(
              'chrono-scrollbar overflow-y-auto overflow-x-hidden',
              'grow p-4',
              '[mask-image:linear-gradient(to_bottom,transparent,#000_20px),linear-gradient(to_left,#000_10px,transparent_10px)]'
            )}
            ref={menuRef}
          >
            <DirectoriesMenu directories={fullDirectories} toc={toc} />
          </div>

          {/* Bottom of menu with theme toggle */}
          <div
            className={clsx(
              'sticky bottom-0',
              'bg-white dark:bg-dark',
              'mx-4 py-4 shadow-[0_-12px_16px_#fff]',
              'flex items-center gap-2',
              'dark:border-neutral-800 dark:shadow-[0_-12px_16px_#111]',
              'contrast-more:border-neutral-400 contrast-more:shadow-none contrast-more:dark:shadow-none',
              'border-t'
            )}
          >
            <div className="flex grow flex-col">
              <ThemeSwitch />
            </div>
          </div>
        </ErrorBoundary>
      </aside>
    </>
  );
}
