import { MdxFile, Meta, MetaJsonFile, PageMapItem, PageOpts } from 'nextra';
import { useMemo } from 'react';
import { usePageOpts } from '@/nextra-theme/PageOptsContext';

export type PageRoute = string;

const isMdxPage = (item: PageMapItem): item is MdxFile => 'route' in item;
const isMetaJsonFile = (item: PageMapItem): item is MetaJsonFile =>
  'data' in item;

/**
 * Using the page map data provided by the Nextra build, searches that map for all pages that are marked as 'hidden'
 * in the `_meta.tsx` files and returns a `Set` with the routes of those hidden pages.
 */
export function useHiddenPageRoutes(): Set<PageRoute> {
  const pageOpts = usePageOpts();
  return useMemo(() => {
    const hiddenRoutes = new Set<PageRoute>();
    addHiddenPageRoutes(pageOpts.pageMap, hiddenRoutes);
    return hiddenRoutes;
  }, [pageOpts.pageMap]);
}

// Adds any hidden page routes to the provided set, searching the list of pages/folders recursively
function addHiddenPageRoutes(
  list: PageOpts['pageMap'],
  hiddenRoutes: Set<PageRoute>
) {
  // Try to find the metadata from the _meta.tsx file in the list of items (it typically is the first item in the
  // list if the file exists for this folder)
  let metaJson: MetaJsonFile['data'] = {};
  for (const item of list) {
    if (isMetaJsonFile(item)) {
      metaJson = item.data;
      break;
    }
  }

  for (const item of list) {
    // We already dealt with the metadata file above, so skip it
    if (isMetaJsonFile(item)) {
      continue;
    }

    // For each MDX page (i.e. docs content), check the metadata for that page to see if it has the
    // `"display": "hidden"` property set
    if (isMdxPage(item)) {
      const metaForPage = metaJson[item.name];
      if (isHidden(metaForPage)) {
        hiddenRoutes.add(item.route);
      }
      continue;
    }

    // For folders, recursively add hidden routes in the children of that folder
    addHiddenPageRoutes(item.children, hiddenRoutes);
  }
}

// Helper function to check the meta object for a MDX page and determine if it's a hidden page
function isHidden(metaForPage?: Meta) {
  return (
    metaForPage !== undefined &&
    typeof metaForPage === 'object' &&
    'display' in metaForPage &&
    metaForPage.display === 'hidden'
  );
}
