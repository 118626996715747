import { clsx } from 'clsx';
import type { ComponentProps, FC } from 'react';
import { useRef } from 'react';
import { WordWrapIcon } from 'nextra/icons';
import { Button } from './Button';
import { CopyToClipboard } from './CopyToClipboard';

export type PreProps = ComponentProps<'pre'> & {
  'data-filename'?: string;
  'data-copy'?: '';
  'data-language'?: string;
  'data-word-wrap'?: '';
  icon?: FC<ComponentProps<'svg'>>;
};
export function Pre({
  children,
  className,
  'data-filename': filename,
  'data-copy': copy,
  ...props
}: PreProps) {
  const preRef = useRef<HTMLPreElement | null>(null);

  const toggleWordWrap = () => {
    const htmlDataset = document.documentElement.dataset;
    const hasWordWrap = 'chronoWordWrap' in htmlDataset;
    if (hasWordWrap) {
      delete htmlDataset.chronoWordWrap;
    } else {
      htmlDataset.chronoWordWrap = '';
    }
  };

  const copyButton = copy === '' && (
    <CopyToClipboard
      className={filename ? '_ml-auto' : ''}
      getValueToCopy={() =>
        preRef.current?.querySelector('code')?.textContent || ''
      }
    />
  );

  return (
    <div className="relative mt-6 first:mt-0">
      {filename && (
        <div className="absolute top-0 z-[1] w-full truncate rounded-t-xl bg-green-700/5 px-4 py-2 text-xs text-gray-700 dark:bg-green-300/10 dark:text-gray-200">
          {filename}
        </div>
      )}
      <pre
        className={clsx(
          // TODO: Remove this class once switched to custom theme
          'chrono-pre',
          'mb-4 overflow-x-auto rounded-xl bg-green-700/5 box-decoration-slice text-[.9em] subpixel-antialiased dark:bg-green-300/10',
          'contrast-more:border contrast-more:border-green-900/20 contrast-more:contrast-150 contrast-more:dark:border-green-100/40',
          filename ? 'pb-4 pt-12' : 'py-4',
          className
        )}
        ref={preRef}
        {...props}
      >
        {children}
      </pre>
      <div
        className={clsx(
          'opacity-0 transition focus-within:opacity-100 [div:hover>&]:opacity-100',
          'absolute right-0 m-[11px] flex gap-1',
          filename ? 'top-8' : 'top-0'
        )}
      >
        <Button
          onClick={toggleWordWrap}
          className="md:hidden"
          title="Toggle word wrap"
        >
          <WordWrapIcon className="pointer-events-none h-4 w-4" />
        </Button>
        {!filename && copyButton}
      </div>
    </div>
  );
}
