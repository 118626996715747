import NextHead from 'next/head';
import { useTheme } from 'next-themes';
import { NextSeo } from 'next-seo';
import { useRouter } from 'nextra/hooks';
import { usePageOpts } from './PageOptsContext';
import { useIsMounted } from './utils';

/**
 * Renders things in the HTML `<head>` element for the current page.
 */
export function Head() {
  const { title, frontMatter } = usePageOpts();
  const { resolvedTheme } = useTheme();
  const isMounted = useIsMounted();
  const { asPath, defaultLocale, locale } = useRouter();
  const url =
    'https://docs.chronosphere.io' +
    (defaultLocale === locale ? asPath : `/${locale}${asPath}`);

  const description = frontMatter.description ?? "Chronosphere's documentation";

  return (
    <>
      <NextSeo
        title={`${title} | Chronosphere Documentation`}
        description={description}
        canonical={frontMatter.canonical}
        openGraph={{
          type: 'article',
          url,
          title,
          siteName: 'Chronosphere Documentation',
          description: description,
          images: [
            {
              url: '/chronosphere-logo.png',
              alt: 'Chronosphere',
            },
          ],
        }}
      />

      <NextHead>
        <link rel="icon" href="/favicon.ico" />

        <meta
          name="robots"
          content={frontMatter.robots || 'index,follow'}
          key="robots"
        />

        {isMounted ? (
          <meta
            name="theme-color"
            content={resolvedTheme === 'dark' ? '#111' : '#fff'}
          />
        ) : (
          <>
            <meta
              name="theme-color"
              content="#fff"
              media="(prefers-color-scheme: light)"
            />
            <meta
              name="theme-color"
              content="#111"
              media="(prefers-color-scheme: dark)"
            />
          </>
        )}

        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, viewport-fit=cover"
        />
      </NextHead>
    </>
  );
}
