import { useState, useContext, useMemo, createContext, useEffect } from 'react';
import { useRouter } from 'nextra/hooks';
import { useIsMounted } from './utils';

export interface MobileMenu {
  isOpen: boolean;
  setIsOpen: (next: boolean) => void;
  // True if the mobile menu is rendered because the user's viewport is small
  // enough, undefined when on the server or waiting to be mounted on the client
  isAvailable?: boolean;
}

const MobileMenuContext = createContext<MobileMenu | undefined>(undefined);

/**
 * Provider that keeps track of the menu state for mobile device support.
 */
export function MobileMenuProvider({
  children,
}: {
  children?: React.ReactNode;
}) {
  // TODO: Should we watch the window for resizing on desktop to change this?
  const isMounted = useIsMounted();
  const isAvailable = isMounted
    ? window.innerWidth < MOBILE_MENU_MAX_WIDTH
    : undefined;

  const [isOpen, setIsOpen] = useState(false);
  const ctxValue: MobileMenu = useMemo(
    () => ({
      isOpen,
      setIsOpen,
      isAvailable,
    }),
    [isOpen, setIsOpen, isAvailable]
  );

  // Add some classes to the body element when the mobile menu is open so that
  // the body isn't scrollable (since the menu is instead)
  useEffect(() => {
    if (isOpen === false) return;

    document.body.classList.add('overflow-hidden', 'md:overflow-auto');
    return () => {
      document.body.classList.remove('overflow-hidden', 'md:overflow-auto');
    };
  }, [isOpen]);

  // Always close the menu when the route changes (e.g. on logo click)
  const router = useRouter();
  useEffect(() => {
    setIsOpen(false);
  }, [router.asPath, setIsOpen]);

  return (
    <MobileMenuContext.Provider value={ctxValue}>
      {children}
    </MobileMenuContext.Provider>
  );
}

/**
 * Gets context for the menu state on mobile devices.
 */
export function useMobileMenu(): MobileMenu {
  const ctx = useContext(MobileMenuContext);
  if (ctx === undefined) {
    throw new Error(
      'No MobileMenu context available. Did you forget a provider?'
    );
  }
  return ctx;
}

/**
 * The mobile menu is rendered if the window width is smaller than this.
 */
const MOBILE_MENU_MAX_WIDTH = 768;
