import { MDXProvider } from 'nextra/mdx';
import { clsx } from 'clsx';
import { useTheme } from 'next-themes';
import { Layout } from '../Layout';
import { HeadingLink } from './HeadingLink';
import { Code } from './Code';
import { Pre } from './Pre';
import { Link } from '@/components';

export interface MdxContentProps {
  children?: React.ReactNode;
}

/**
 * Renders the MDX content for a page.
 */
export function MdxContent({ children }: MdxContentProps) {
  const { resolvedTheme } = useTheme();
  return (
    <MDXProvider components={mdxComponents(resolvedTheme)}>
      {children}
    </MDXProvider>
  );
}

/**
 * Components used when rendering the HTML markup for MDX content.
 */
const mdxComponents: (
  theme?: string
) => Parameters<typeof MDXProvider>[0]['components'] = (theme?: string) => ({
  h1: (props) => (
    <h1
      className="mt-2 font-primary-header text-4xl font-medium tracking-tight text-slate-900 dark:text-slate-100"
      {...props}
    />
  ),
  h2: (props) => <HeadingLink level="2" {...props} />,
  h3: (props) => <HeadingLink level="3" {...props} />,
  h4: (props) => <HeadingLink level="4" {...props} />,
  h5: (props) => <HeadingLink level="5" {...props} />,
  h6: (props) => <HeadingLink level="6" {...props} />,
  ul: (props) => (
    <ul className="mt-6 list-disc first:mt-0 ltr:ml-6 rtl:mr-6" {...props} />
  ),
  ol: (props) => (
    <ol className="mt-6 list-decimal first:mt-0 ltr:ml-6 rtl:mr-6" {...props} />
  ),
  li: (props) => <li className="my-2" {...props} />,
  blockquote: (props) => (
    <blockquote
      className={clsx(
        'mt-6 border-gray-300 italic text-gray-700 dark:border-gray-700 dark:text-gray-400',
        'first:mt-0 ltr:border-l-2 ltr:pl-6 rtl:border-r-2 rtl:pr-6'
      )}
      {...props}
    />
  ),
  hr: (props) => (
    <hr
      className="my-8 border-neutral-200/70 contrast-more:border-neutral-400 dark:border-green-100/10 contrast-more:dark:border-neutral-400"
      {...props}
    />
  ),
  a: Link,
  p: (props) => <p className="mt-6 leading-7 first:mt-0" {...props} />,
  table: (props) => (
    <table
      className="chrono-scrollbar mt-6 block overflow-x-scroll p-0 first:mt-0"
      {...props}
    />
  ),
  tr: (props) => (
    <tr
      className="m-0 border-t border-gray-300 p-0 even:bg-gray-100 dark:border-gray-600 even:dark:bg-gray-600/20"
      {...props}
    />
  ),
  th: (props) => (
    <th
      className="m-0 border border-gray-300 px-4 py-2 font-semibold dark:border-gray-600"
      {...props}
    />
  ),
  td: (props) => (
    <td
      className="m-0 border border-gray-300 px-4 py-2 dark:border-gray-600"
      {...props}
    />
  ),
  pre: Pre,
  code: Code,
  b: (props) => <b className="font-semibold" {...props} />,
  strong: (props) => <strong className="font-semibold" {...props} />,
  wrapper: (props) => <Layout theme={theme} {...props} />,
});
