import Image from 'next/image';
import lightThemeImage from '../../../public/logo__chrono-black.svg';
import darkThemeImage from '../../../public/logo__chrono-white.svg';

export type LogoImageProps = Omit<Parameters<typeof Image>[0], 'src'> & {
  theme?: string;
};

/**
 * Main logo image for the site that renders the correct image based on the theme (i.e. dark vs light mode).
 */
export function LogoImage({ theme, ...props }: LogoImageProps) {
  let src = lightThemeImage;
  if (theme === 'dark') {
    src = darkThemeImage;
  }

  // "alt" is a required prop for consumers
  // eslint-disable-next-line jsx-a11y/alt-text
  return <Image src={src} {...props} />;
}
