import { Item, PageItem } from 'nextra/normalize-pages';
import { clsx } from 'clsx';
import { Heading } from 'nextra';
import { Folder } from './Folder';
import { File } from './File';
import { MenuClasses } from './menu-classes';
import { Separator } from './Separator';

export interface MenuProps {
  directories: PageItem[] | Item[];
  toc: Heading[];
  className?: string;
  onlyCurrentDocs?: boolean;
}

/**
 * Renders a list of menu items for the specified directories.
 */
export function Menu({
  directories,
  className,
  onlyCurrentDocs = false,
  toc,
}: MenuProps) {
  return (
    <ul className={clsx(MenuClasses.LIST, className)}>
      {directories.map((item) => {
        if (onlyCurrentDocs === true && !item.isUnderCurrentDocsTree) {
          return null;
        }

        if (item.type === 'menu') {
          // TODO: We should exclude this from normalize pages so we don't have to handle it?
          throw new Error(
            'Item "${item.name}" with type "menu" is not supported'
          );
        }

        if (
          item.children &&
          (item.children.length > 0 || !item.withIndexPage)
        ) {
          return <Folder key={item.name} item={item} toc={toc} />;
        }

        if (item.type === 'separator') {
          return <Separator key={item.name} title={item.title} />;
        }

        return <File key={item.name} item={item} toc={toc} />;
      })}
    </ul>
  );
}
