import { useState, useRef, useEffect } from 'react';
import { clsx } from 'clsx';
import { ExpandIcon } from 'nextra/icons';
import scrollIntoView from 'scroll-into-view-if-needed';
import { Heading } from 'nextra';
import { useNormalizedPages } from '@/nextra-theme/NormalizedPagesContext';
import { useMobileMenu } from '@/nextra-theme/MobileMenuContext';
import {
  Collapse,
  ErrorBoundary,
  ThemeSwitch,
  DirectoriesMenu,
} from '@/nextra-theme/components';

// TODO: Turn this into a theme configuration?
const SHOW_SIDEBAR_TOGGLE = false;

/**
 * The main Sidebar displayed on the left-hand side of all pages which includes
 * navigation to the docs content. Hidden on mobile devices since the MobileMenu
 * renders those links instead.
 */
export function Sidebar({ toc }: { toc: Heading[] }) {
  const mobileMenu = useMobileMenu();

  const { docsDirectories, activeType, activeThemeContext } =
    useNormalizedPages();
  const [showSidebar, setShowSidebar] = useState(true);
  const [showToggleAnimation, setToggleAnimation] = useState(false);

  const containerRef = useRef<HTMLElement>(null);
  const sidebarRef = useRef<HTMLDivElement>(null);

  // Find the active list item in the sidebar and scroll it into view on first
  // load
  useEffect(() => {
    const activeElement = sidebarRef.current?.querySelector('li.active');
    if (activeElement) {
      scrollIntoView(activeElement, {
        block: 'center',
        inline: 'center',
        scrollMode: 'always',
        boundary: containerRef.current,
      });
    }
  }, []);

  // For alternative page layouts like 'raw' and 'full', don't render the
  // Sidebar at all
  if (activeThemeContext.layout !== 'default') {
    return null;
  }

  return (
    <aside
      className={clsx(
        // Sidebar is hidden on mobile by default since the MobileMenu contains
        // all the links instead
        'hidden shrink-0 flex-col self-start md:flex',
        'bg-white dark:bg-dark',
        'sticky bottom-0 top-16 overscroll-contain',
        'transition-all ease-in-out motion-reduce:transform-none',
        showSidebar ? 'w-64' : 'w-20',
        'pt-0',
        'print:hidden'
      )}
      ref={containerRef}
    >
      {/*
        Normally the activeType will be `docs` and so we'll render the Sidebar
        contents as usual, but for the `page` type (which can be set in the
        metadata), the Sidebar is just an empty placeholder <aside> to help
        maintain the default layout.
       */}
      {activeType !== 'page' && (
        <ErrorBoundary>
          <div
            className={clsx(
              'overflow-y-auto overflow-x-hidden',
              'h-[calc(100vh-var(--chrono-navbar-height)-var(--chrono-menu-height))] grow p-4',
              showSidebar ? 'chrono-scrollbar' : 'chrono-no-scrollbar'
            )}
            ref={sidebarRef}
          >
            {/*
              Don't do the extra rendering work of all the menu items if the
              sidebar is hidden or if we know the mobile menu is available since
              that means we're on the client and know this is all hidden anyway
            */}
            {showSidebar && mobileMenu.isAvailable !== true && (
              <Collapse isOpen={showSidebar} horizontal>
                <DirectoriesMenu
                  directories={docsDirectories}
                  onlyCurrentDocs
                  toc={toc}
                />
              </Collapse>
            )}
          </div>

          {/* Bottom of sidebar with theme toggle */}
          <div
            className={clsx(
              'sticky bottom-0',
              'bg-white dark:bg-dark',
              'mx-4 py-4 shadow-[0_-12px_16px_#fff]',
              'flex items-center gap-2',
              'dark:border-neutral-800 dark:shadow-[0_-12px_16px_#111]',
              'contrast-more:border-neutral-400 contrast-more:shadow-none contrast-more:dark:shadow-none',
              showSidebar ? 'border-t' : 'flex-wrap justify-center py-4'
            )}
          >
            <div className={showSidebar ? 'flex grow flex-col' : ''}>
              <ThemeSwitch compact={!showSidebar} />
            </div>

            {SHOW_SIDEBAR_TOGGLE && (
              <button
                title={showSidebar ? 'Hide sidebar' : 'Show sidebar'}
                className={clsx(
                  'h-7 rounded-md px-2 text-gray-600 transition-colors transition-transform hover:bg-gray-100 hover:text-gray-900 dark:text-gray-400 dark:hover:bg-green-100/5 dark:hover:text-gray-50',
                  // TODO: These might need to be applied to the theme switch button also
                  showToggleAnimation && 'animate-fade-in opacity-0',
                  !showSidebar && 'ltr:rotate-180'
                )}
                onClick={() => {
                  setShowSidebar((current) => !current);
                  setToggleAnimation(true);
                }}
              >
                <ExpandIcon />
              </button>
            )}
          </div>
        </ErrorBoundary>
      )}
    </aside>
  );
}
