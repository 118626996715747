import { Heading } from 'nextra';
import { Item, PageItem } from 'nextra/normalize-pages';
import { useFSRoute } from 'nextra/hooks';
import { clsx } from 'clsx';
import { useFocusedItem } from './FocusedItemContext';
import { MenuClasses } from './menu-classes';
import { Anchor } from '@/components';
import { useMobileMenu } from '@/nextra-theme/MobileMenuContext';
import { activeAnchor } from '@/nextra-theme/active-anchor';

export interface FileProps {
  item: PageItem | Item;
  toc: Heading[];
}

/**
 * A file in the sidebar menu that links/navigates to the appropriate page.
 */
export function File({ item, toc }: FileProps) {
  const { onItemFocus, onItemBlur } = useFocusedItem();
  const mobileMenu = useMobileMenu();

  // It is possible that the item doesn't have any route - for example an external link.
  // (TODO: Why don't the types from normalize pages reflect this?)
  const route = useFSRoute();
  const isActive =
    item.route !== undefined && [route, route + '/'].includes(item.route + '/');

  let newWindow = false;
  let href = item.route;
  if ('href' in item) {
    newWindow = item.newWindow ?? false;
    href = item.href ?? item.route;
  }

  return (
    <li className={clsx(MenuClasses.LIST, { active: isActive })}>
      <Anchor
        href={href}
        newWindow={newWindow}
        className={clsx(
          MenuClasses.LINK,
          isActive ? MenuClasses.ACTIVE : MenuClasses.INACTIVE
        )}
        onClick={() => {
          mobileMenu.setIsOpen(false);
        }}
        onFocus={() => onItemFocus(item.route)}
        onBlur={onItemBlur}
      >
        {item.title}
      </Anchor>
      {/* On mobile, show the table of contents for the active doc in the menu */}
      {isActive && mobileMenu.isAvailable && toc.length > 0 && (
        <ul
          className={clsx(
            MenuClasses.LIST,
            MenuClasses.BORDER,
            'ltr:ml-3 rtl:mr-3'
          )}
        >
          {toc.map(({ id, value }) => (
            <li key={id}>
              <Anchor
                href={`#${id}`}
                className={clsx(
                  MenuClasses.LINK,
                  'flex gap-2 before:opacity-25 before:content-["#"]',
                  activeAnchor[id]?.isActive
                    ? MenuClasses.ACTIVE
                    : MenuClasses.INACTIVE
                )}
                onClick={() => {
                  mobileMenu.setIsOpen(false);
                }}
              >
                {value}
              </Anchor>
            </li>
          ))}
        </ul>
      )}
    </li>
  );
}
