import { Tab as HeadlessTab } from '@headlessui/react';
import { clsx } from 'clsx';
import { Children, useState } from 'react';

export interface TabsProps {
  children?: Array<React.ReactElement<TabProps>>;
}

function _Tabs({ children }: TabsProps) {
  const labels = Children.map(children, (child) => {
    return child?.props.label;
  });

  const [selectedIndex, setSelectedIndex] = useState(0);

  return (
    <HeadlessTab.Group
      selectedIndex={selectedIndex}
      defaultIndex={0}
      onChange={(next) => setSelectedIndex(next)}
    >
      <div className="chrono-scrollbar overflow-x-auto overflow-y-hidden overscroll-x-contain">
        <HeadlessTab.List className="mt-4 flex w-max min-w-full border-b border-gray-200 pb-px dark:border-neutral-800">
          {labels?.map((label, index) => {
            return (
              <HeadlessTab
                key={index}
                className={({ selected }) =>
                  clsx(
                    'mr-2 rounded-t p-2 font-medium leading-5 transition-colors',
                    '-mb-0.5 select-none border-b-4',
                    selected
                      ? 'border-green-500 text-green-500'
                      : 'border-transparent text-gray-600 hover:border-gray-200 hover:text-black dark:text-gray-200 dark:hover:border-neutral-800 dark:hover:text-white'
                  )
                }
              >
                {label}
              </HeadlessTab>
            );
          })}
        </HeadlessTab.List>
      </div>
      <HeadlessTab.Panels>{children}</HeadlessTab.Panels>
    </HeadlessTab.Group>
  );
}

export interface TabProps {
  label: string;
  id?: string;
  children: React.ReactNode;
}

function Tab({ id, children }: TabProps) {
  return (
    <HeadlessTab.Panel id={id} className="bg-gray-50 p-6 dark:bg-gray-900">
      {children}
    </HeadlessTab.Panel>
  );
}

export const Tabs = Object.assign(_Tabs, { displayName: 'Tabs', Tab });
