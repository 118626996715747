// Types that come with flexsearch are incorrect, see tsconfig.json for override
import FlexSearch from 'flexsearch';
import { useRouter } from 'nextra/hooks';
import { useQuery } from '@tanstack/react-query';
import type { SearchData } from 'nextra';
import { PageRoute, useHiddenPageRoutes } from './hidden-pages';
import { fetchJson } from '@/nextra-theme/utils';
import { DEFAULT_LOCALE } from '@/utils';

/**
 * The searchable FlexSearch indexes that are created from data compiled by
 * Nextra during the build (and fetched from server).
 */
export interface FlexSearchIndexes {
  pageIndex: FlexSearch.Document<PageDocument, string[]>;
  sectionIndex: FlexSearch.Document<SectionDocument, string[]>;
}

/**
 * A match when searching/retrieving from the pageIndex.
 */
export interface PageDocument {
  title: string;
}

/**
 * A match when searching/retrieving data from the sectionIndex.
 */
export interface SectionDocument {
  type: SectionDocumentType;
  url: string;
  sectionTitle: string;
  displayContent: string;
}

/**
 * The type of document in the section index (i.e. a section title or its content)
 */
export enum SectionDocumentType {
  TITLE = 1,
  CONTENT = 2,
}

/**
 * Hook that fetches structured data from the server created by Nextra during
 * the build and converts it to FlexSearch indexes that can be used on the
 * client for search.
 */
export function useFlexSearchIndexes(isEnabled: boolean) {
  // Get a list of routes that are marked as 'hidden' in the page metadata so we can exclude them from the indexes
  const hiddenPageRoutes = useHiddenPageRoutes();

  const { locale = DEFAULT_LOCALE, basePath } = useRouter();
  return useQuery({
    queryKey: ['FlexSearch', basePath, locale],
    queryFn: async () => {
      // Get structured data created by Nextra during the build from the server
      const searchData = await fetchJson<SearchData>(
        `${basePath}/_next/static/chunks/nextra-data-${locale}.json`
      );
      return createIndexesFromSearchData(searchData, hiddenPageRoutes);
    },
    enabled: isEnabled,
    // This should be fetched once and then never again
    staleTime: Number.POSITIVE_INFINITY,
    gcTime: Number.POSITIVE_INFINITY,
  });
}

// Additional data that's used when adding documents to the PageIndex, but not
// stored and so not available when searching/retrieving
interface InternalPageDocumentData extends PageDocument {
  id: string;
  // This is the field that gets indexed for searching
  content: string;
}

// Additional data that's used when adding documents to the SectionIndex, but
// not stored and so not available when searching/retrieving
interface InternalSectionDocumentData extends SectionDocument {
  id: string;
  pageId: string;
  // This is the field that gets indexed for searching
  content: string;
}

// Transforms the SearchData from the server into FlexSearch indexes
function createIndexesFromSearchData(
  searchData: SearchData,
  hiddenPageRoutes: Set<PageRoute>
): FlexSearchIndexes {
  // Create search indexes for pages/sections using the internal types, but
  // the indexes are returned as the public/searchable types
  const pageIndex = new FlexSearch.Document<InternalPageDocumentData, string[]>(
    {
      cache: 100,
      tokenize: 'full',
      document: {
        id: 'id',
        // Index on the internal "content" field
        index: 'content',
        // Fields in the document stored and returned in search results
        store: ['title'],
      },
      context: {
        resolution: 9,
        depth: 2,
        bidirectional: true,
      },
    }
  );

  const sectionIndex = new FlexSearch.Document<
    InternalSectionDocumentData,
    string[]
  >({
    cache: 100,
    tokenize: 'full',
    document: {
      id: 'id',
      // Index on the internal "content" field
      index: 'content',
      // Tag sections with what page.id they belong to so when searching this
      // index, you can search just on a specific page
      tag: 'pageId',
      // Fields in the document stored and returned in search results
      store: ['type', 'url', 'sectionTitle', 'displayContent'],
    },
    context: {
      resolution: 9,
      depth: 2,
      bidirectional: true,
    },
  });

  // Populate the search indexes from the structured data that we fetched
  let pageCounter = 0;
  for (const [pageRoute, pageData] of Object.entries(searchData)) {
    // If this page is hidden, skip it so it doesn't get indexed and thus is omitted from search results
    if (hiddenPageRoutes.has(pageRoute)) {
      continue;
    }

    // Build up pageContent from all section content on the page
    let pageContent = '';
    ++pageCounter;
    const pageId = `page_${pageCounter}`;

    for (const [sectionKey, sectionContent] of Object.entries(pageData.data)) {
      // sectionKey will be '' for any content at the beginning of a document
      // that's not under a sub-header (e.g. <h2>), otherwise it contains an
      // anchor id and the text of the heading separated by a '#'
      const [sectionId, sectionTitle = pageData.title] = sectionKey.split('#');

      let url = pageRoute;
      if (sectionId !== '') {
        url += `#${sectionId}`;
      }

      const sectionContentLines = sectionContent.split('\n');

      // Add an entry to make the section/page title searchable in the index
      sectionIndex.add({
        id: url,
        type: SectionDocumentType.TITLE,
        url,
        sectionTitle,
        pageId,
        // The section title itself is searchable...
        content: sectionTitle,
        // ...but in results, show the first line of the section's content
        displayContent:
          sectionContentLines[0] !== '' ? sectionContentLines[0] : sectionTitle,
      });

      // Add entries for each line of content in the section
      for (let i = 0; i < sectionContentLines.length; i++) {
        sectionIndex.add({
          id: `${url}_${i}`,
          type: SectionDocumentType.CONTENT,
          url,
          sectionTitle,
          pageId,
          // Each line of content is searchable...
          content: sectionContentLines[i],
          // ...and also displayed in the results
          displayContent: sectionContentLines[i],
        });
      }

      // Add to the page itself
      pageContent += ` ${sectionTitle} ${sectionContent}`;
    }

    pageIndex.add({
      id: pageId,
      title: pageData.title,
      content: pageContent,
    });
  }

  return { pageIndex, sectionIndex };
}
