import { Heading } from 'nextra';
import { clsx } from 'clsx';
import { TocHeading } from './TocHeading';
import { useNormalizedPages } from '@/nextra-theme/NormalizedPagesContext';

// CSS classes applied to the root element of the TOC
const rootClassName =
  'order-last hidden w-64 shrink-0 px-4 print:hidden xl:block';

/**
 * The table of contents displayed to the right of a documentation page. Hidden
 * on mobile devices since the ToC elements are displayed in the MobileMenu
 * instead.
 */
export function TableOfContents({ toc }: { toc: Heading[] }) {
  const { activeType, activeThemeContext } = useNormalizedPages();

  // TODO: Scroll tracking
  const isActive = false;

  // For `full` layout, don't render anything for the TOC
  if (activeThemeContext.layout === 'full') {
    return null;
  }

  // By default, `activeType` will be `doc`, but for `page` type (which can be
  // set in the metadata), render a placeholder `div` to preserve the default
  // layout, but a page won't have TOC items, so it's empty
  if (activeType === 'page') {
    return <div className={rootClassName} />;
  }

  // But normally, render the TOC for the doc page with the list of headings
  // that are on the page
  return (
    <nav className={rootClassName}>
      <div
        className={clsx(
          'chrono-scrollbar sticky top-16 overflow-y-auto pr-4 pt-6 text-sm [hyphens:auto]',
          'max-h-[calc(100vh-var(--chrono-navbar-height)-env(safe-area-inset-bottom))] ltr:-mr-4 rtl:-ml-4'
        )}
      >
        {toc.length > 0 && (
          <>
            <p className="mb-4 font-semibold tracking-tight">On This Page</p>
            <ul>
              {toc.map((heading) => (
                <TocHeading
                  key={heading.id}
                  heading={heading}
                  isActive={isActive}
                />
              ))}
            </ul>
          </>
        )}
      </div>
    </nav>
  );
}
